
        @use 'sass:list';
        @use 'sass:map';
        @use "sass:math";

        
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "mixins";

@layer utilities {
    .scroll-container-x {
        @include scroll-container(x);
    }

    .scroll-container-y {
        @include scroll-container(y);
    }

    .h-svh-wrapper,
    .h-svh-section {
        // 16px = 8px between sections * 2 || 8px wrapper pt + 8px between sections
        height: calc(100vh - 16px);
        height: calc(100svh - 16px);
        height: calc(var(--vh, 1svh) * 100 - 16px);
    }

    .h-svh-wrapper {
        @screen md {
            // 24px = 16px wrapper pt + 8px between sections
            height: calc(100vh - 24px);
            height: calc(100svh - 24px);
            height: calc(var(--vh, 1svh) * 100 - 24px);
        }

        @screen lg {
            // 20px = 12px wrapper pt + 8px between sections
            height: calc(100vh - 20px);
            height: calc(100svh - 20px);
            height: calc(var(--vh, 1svh) * 100 - 20px);
        }
    }

    .min-h-svh-wrapper,
    .min-h-svh-section {
        // 16px = 8px between sections * 2 || 8px wrapper pt + 8px between sections
        min-height: calc(100vh - 16px);
        min-height: calc(100svh - 16px);
        min-height: calc(var(--vh, 1svh) * 100 - 16px);
    }

    .min-h-svh-wrapper {
        @screen md {
            // 24px = 16px wrapper pt + 8px between sections
            min-height: calc(100vh - 24px);
            min-height: calc(100svh - 24px);
            min-height: calc(var(--vh, 1svh) * 100 - 24px);
        }

        @screen lg {
            // 20px = 12px wrapper pt + 8px between sections
            min-height: calc(100vh - 20px);
            min-height: calc(100svh - 20px);
            min-height: calc(var(--vh, 1svh) * 100 - 20px);
        }
    }

    .scroll-m-t-header {
        scroll-margin-top: var(--header-scroll-offset, 8px);

        @screen md {
            scroll-margin-top: var(--header-scroll-offset, 16px);
        }

        @screen lg {
            scroll-margin-top: var(--header-scroll-offset, 12px);
        }
    }

    .cursor-custom-forbidden {
        cursor:
            url("../../shared/assets/images/cursor-forbidden.svg") 12 12,
            not-allowed;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
    outline: none;
    scroll-margin-top: calc(var(--header-scroll-offset, 8px) - 8px);

    @screen md {
        scroll-margin-top: calc(var(--header-scroll-offset, 16px) - 16px);
    }

    @screen lg {
        scroll-margin-top: calc(var(--header-scroll-offset, 12px) - 12px);
    }
}

html {
    &.is-scroll-locked {
        overflow: hidden;
    }
}

body {
    text-decoration-skip: objects;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;

    .is-scroll-locked & {
        overflow: hidden;
    }

    .is-scroll-locked:not(.no-scrollbar) & {
        padding-right: var(--scrollbar-width, 0);

        &::before {
            display: block;
            z-index: 1001;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            content: "";
            pointer-events: none;
        }
    }
}

main {
    flex: 1;
}

a {
    text-decoration: none;
    text-decoration-skip-ink: none;
}

img,
svg {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
}

.grecaptcha-badge {
    visibility: hidden;
}
