@mixin scroll-container($axis: y, $hide-scrollbar: true) {
    -webkit-overflow-scrolling: touch;
    overflow: hidden;

    @if $axis == x {
        overflow-x: auto;
    } @else if $axis == y {
        overflow-y: auto;
    }

    @if $hide-scrollbar {
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
}
